var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"event-details-report"}},[_c('h1',[_vm._v(_vm._s(_vm.$t("REPORTS.EVENT_DETAILS_REPORT_PAGE.TITLE")))]),_c('v-app',[_c('form',{staticClass:"mt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"event"}},[_vm._v(_vm._s(_vm.$t("REPORTS.EVENT_DETAILS_REPORT_PAGE.EVENT")))]),_c('v-autocomplete',{attrs:{"filled":"","solo":"","multiple":"","value":_vm.chosenEvents,"items":_vm.events,"item-text":"eventName","item-value":"id","placeholder":_vm.$t('REPORTS.EVENT_DETAILS_REPORT_PAGE.EVENT_PLACEHOLDER')},on:{"change":_vm.selectEvents}})],1),_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"event"}},[_vm._v(_vm._s(_vm.$t("REPORTS.EVENT_DETAILS_REPORT_PAGE.DOCTOR")))]),_c('v-autocomplete',{attrs:{"filled":"","solo":"","multiple":"","value":_vm.chosenDoctors,"items":_vm.assignedDoctors,"item-text":"name","item-disabled":_vm.isItemDisabled,"return-object":"","placeholder":_vm.$t('REPORTS.EVENT_DETAILS_REPORT_PAGE.DOCTOR_PLACEHOLDER')},on:{"change":_vm.selectDoctors}})],1)]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"btn-primary",attrs:{"disabled":_vm.selectedEvents.length === 0 || _vm.selectedDoctors.length === 0},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t("COMMON.SUBMIT")))])],1)])]),(_vm.showAssignedDoctorsTable)?[[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"event"}},[_vm._v(_vm._s(_vm.$t("REPORTS.EVENT_DETAILS_REPORT_PAGE.SPECIALITY")))]),_c('v-autocomplete',{attrs:{"filled":"","solo":"","multiple":"","value":_vm.chosenSpeciailities,"items":_vm.specialities,"item-text":"TextEn","item-value":"value","placeholder":_vm.$t(
                    'REPORTS.EVENT_DETAILS_REPORT_PAGE.SPECIALITY_PLACEHOLDER'
                  )},on:{"change":_vm.selectSpeciality}})],1),_c('div',{staticClass:"col-6"},[_c('label',{attrs:{"for":"event"}},[_vm._v(_vm._s(_vm.$t("REPORTS.EVENT_DETAILS_REPORT_PAGE.GOVERNORATE")))]),_c('v-autocomplete',{attrs:{"filled":"","solo":"","multiple":"","value":_vm.chosenGovernates,"items":_vm.governorates,"item-text":"governorate_name_en","item-value":"value","placeholder":_vm.$t(
                    'REPORTS.EVENT_DETAILS_REPORT_PAGE.GOVERNORATE_PLACEHOLDER'
                  )},on:{"change":_vm.selectGovernorate}})],1)])])])],_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.exportExcel('xls')}}},[_vm._v(_vm._s(_vm.$t("COMMON.EXTRACT")))])],1),_c('h3',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("EVENT.EVENT_DETAILS.ASSIGNED_DOCTORS")))]),(_vm.reportAssignedDoctors.length > 0)?_c('b-table',{ref:"exportable_table",attrs:{"striped":"","hover":"","fields":_vm.$t('DOCTOR.REPORT_FIELDS'),"items":_vm.reportAssignedDoctors,"id":"exportable_table"},scopedSlots:_vm._u([{key:"cell(speciality)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.getSpeciality(row.item.speciality))+" ")]}},{key:"cell(governorate)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.getGovernorate(row.item.governorate))+" ")]}},{key:"cell(mobileNo)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.getPhoneNumber(row.item.mobileNo))+" ")]}},{key:"cell(questionnaireSubmitted)",fn:function(row){return [(!row.item.questionnaireSubmitted)?_c('b-button',{staticClass:"mr-2 btn-primary",attrs:{"size":"sm","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t("REPORTS.EVENT_DETAILS_REPORT_PAGE.NOT_SUBMITTED"))+" ")]):_c('b-button',{staticClass:"mr-2 btn-primary",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$router.push({
              name: 'Questionnaire Details',
              params: {
                eventId: row.item.eventId,
                doctorId: row.item.doctorId,
              },
            })}}},[_vm._v(" "+_vm._s(_vm.$t("REPORTS.EVENT_DETAILS_REPORT_PAGE.GO_TO_QUESTIONNAIRE"))+" ")])]}}],null,false,4119581973)}):[_c('b-alert',{staticClass:"mt-5",attrs:{"show":""}},[_vm._v("Cannot find anything with the current filters")])]]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }