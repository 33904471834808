<template>
  <div id="event-details-report">
    <h1>{{ $t("REPORTS.EVENT_DETAILS_REPORT_PAGE.TITLE") }}</h1>
    <v-app>
      <form class="mt-5">
        <div class="row">
          <div class="col-6">
            <label for="event">{{
              $t("REPORTS.EVENT_DETAILS_REPORT_PAGE.EVENT")
            }}</label>
            <v-autocomplete
              filled
              solo
              multiple
              @change="selectEvents"
              :value="chosenEvents"
              :items="events"
              item-text="eventName"
              item-value="id"
              :placeholder="
                $t('REPORTS.EVENT_DETAILS_REPORT_PAGE.EVENT_PLACEHOLDER')
              "
            ></v-autocomplete>
          </div>
          <div class="col-6">
            <label for="event">{{
              $t("REPORTS.EVENT_DETAILS_REPORT_PAGE.DOCTOR")
            }}</label>
            <v-autocomplete
              filled
              solo
              multiple
              @change="selectDoctors"
              :value="chosenDoctors"
              :items="assignedDoctors"
              item-text="name"
              :item-disabled="isItemDisabled"
              return-object
              :placeholder="
                $t('REPORTS.EVENT_DETAILS_REPORT_PAGE.DOCTOR_PLACEHOLDER')
              "
            ></v-autocomplete>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <b-button
            :disabled="
              selectedEvents.length === 0 || selectedDoctors.length === 0
            "
            @click="onSubmit"
            class="btn-primary"
            >{{ $t("COMMON.SUBMIT") }}</b-button
          >
        </div>
      </form>
    </v-app>
    <template v-if="showAssignedDoctorsTable">
      <template>
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-6">
                <label for="event">{{
                  $t("REPORTS.EVENT_DETAILS_REPORT_PAGE.SPECIALITY")
                }}</label>
                <v-autocomplete
                  filled
                  solo
                  multiple
                  @change="selectSpeciality"
                  :value="chosenSpeciailities"
                  :items="specialities"
                  item-text="TextEn"
                  item-value="value"
                  :placeholder="
                    $t(
                      'REPORTS.EVENT_DETAILS_REPORT_PAGE.SPECIALITY_PLACEHOLDER'
                    )
                  "
                ></v-autocomplete>
              </div>
              <div class="col-6">
                <label for="event">{{
                  $t("REPORTS.EVENT_DETAILS_REPORT_PAGE.GOVERNORATE")
                }}</label>
                <v-autocomplete
                  filled
                  solo
                  multiple
                  @change="selectGovernorate"
                  :value="chosenGovernates"
                  :items="governorates"
                  item-text="governorate_name_en"
                  item-value="value"
                  :placeholder="
                    $t(
                      'REPORTS.EVENT_DETAILS_REPORT_PAGE.GOVERNORATE_PLACEHOLDER'
                    )
                  "
                ></v-autocomplete>
              </div>
            </div>
          </div>
        </div>
      </template>

      <div class="d-flex justify-content-end">
        <b-button @click="exportExcel('xls')" class="btn-success">{{
          $t("COMMON.EXTRACT")
        }}</b-button>
      </div>
      <h3 class="mt-2">{{ $t("EVENT.EVENT_DETAILS.ASSIGNED_DOCTORS") }}</h3>
      <b-table
        v-if="reportAssignedDoctors.length > 0"
        striped
        hover
        :fields="$t('DOCTOR.REPORT_FIELDS')"
        :items="reportAssignedDoctors"
        ref="exportable_table"
        id="exportable_table"
      >
        <template #cell(speciality)="row">
          {{ getSpeciality(row.item.speciality) }}
        </template>
        <template #cell(governorate)="row">
          {{ getGovernorate(row.item.governorate) }}
        </template>
        <template #cell(mobileNo)="row">
          {{ getPhoneNumber(row.item.mobileNo) }}
        </template>
        <template #cell(questionnaireSubmitted)="row">
          <b-button
            size="sm"
            v-if="!row.item.questionnaireSubmitted"
            disabled
            class="mr-2 btn-primary"
          >
            {{ $t("REPORTS.EVENT_DETAILS_REPORT_PAGE.NOT_SUBMITTED") }}
          </b-button>
          <b-button
            size="sm"
            v-else
            @click="
              $router.push({
                name: 'Questionnaire Details',
                params: {
                  eventId: row.item.eventId,
                  doctorId: row.item.doctorId,
                },
              })
            "
            class="mr-2 btn-primary"
          >
            {{ $t("REPORTS.EVENT_DETAILS_REPORT_PAGE.GO_TO_QUESTIONNAIRE") }}
          </b-button>
        </template>
      </b-table>
      <template v-else>
        <b-alert class="mt-5" show
          >Cannot find anything with the current filters</b-alert
        >
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import constants from "@/common/config/constants.json";
import { utils, write, writeFile } from "xlsx";
import * as $ from "jquery";

export default {
  created() {
    this.getEvents();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name !== "Questionnaire Details") {
      next((vm) => {
        vm.SET_SELECTED_EVENT(null);
        vm.SET_SELECTED_DOCTOR(null);
        vm.SET_REPORT_ASSIGNED_DOCTORS([]);
        vm.SET_ASSIGNED_DOCTORS_TABLE(false);
        vm.SET_SELECTED_SPECIALITY(null);
        vm.SET_SELECTED_GOVERNATE(null);

        next();
      });
    } else {
      next();
    }
  },
  // watch: {
  //   chosenSpeciailities() {
  //     if (this.chosenSpeciailities.length > 0) {
  //       this.SET_REPORT_ASSIGNED_DOCTORS(
  //         this.assignedDoctors.filter(
  //           (doc) =>
  //             doc.speciality === this.selectedSpeciality &&
  //             doc.governorate === this.selectedGovernorate
  //         )
  //       );
  //     }
  //   },
  //   chosenGovernates() {
  //     if (this.chosenGovernates.length > 0) {
  //       this.SET_REPORT_ASSIGNED_DOCTORS(
  //         this.assignedDoctors.filter(
  //           (doc) =>
  //             doc.speciality === this.selectedSpeciality &&
  //             doc.governorate === this.selectedGovernorate
  //         )
  //       );
  //     }
  //   },
  // },
  data() {
    return {
      filteredDoctors: [],
      chosenEvents: [],
      chosenDoctors: [],
      chosenSpeciailities: [],
      chosenGovernates: [],
      doctorsFiltered: [],
    };
  },
  methods: {
    ...mapMutations({
      SET_ASSIGNED_DOCTORS: "SET_ASSIGNED_DOCTORS",
      SET_SELECTED_DOCTOR: "SET_SELECTED_DOCTOR",
      SET_SELECTED_EVENT: "SET_SELECTED_EVENT",
      SET_ASSIGNED_DOCTORS_TABLE: "SET_ASSIGNED_DOCTORS_TABLE",
      SET_REPORT_ASSIGNED_DOCTORS: "SET_REPORT_ASSIGNED_DOCTORS",
      SET_SELECTED_SPECIALITY: "SET_SELECTED_SPECIALITY",
      SET_SELECTED_GOVERNATE: "SET_SELECTED_GOVERNATE",
      SET_SELECTED_EVENTS: "SET_SELECTED_EVENTS",
      SET_SELECTED_DOCTORS: "SET_SELECTED_DOCTORS",
      SET_SELECTED_SPECIALITIES: "SET_SELECTED_SPECIALITIES",
      SET_SELECTED_GOVERNATES: "SET_SELECTED_GOVERNATES",
    }),
    ...mapActions({
      getEvents: "getEvents",
      getAssignedDoctors: "getAssignedDoctors",
      getAssignedDoctorsForMultipleEvents:
        "getAssignedDoctorsForMultipleEvents",
    }),
    selectEvents(values) {
      this.getAssignedDoctorsForMultipleEvents({
        eventIds: values,
        report: true,
      });
      this.SET_SELECTED_EVENTS(values);
    },
    selectDoctors(value) {
      this.chosenDoctors = value;
      this.SET_SELECTED_DOCTORS(value);
    },
    isItemDisabled(item) {
      // Check if "Select All" is chosen
      const isSelectAllChosen = this.chosenDoctors.some(
        (doctor) => doctor.doctorId === "all"
      );

      // If "Select All" is chosen, disable all items except "Select All"
      if (isSelectAllChosen) {
        return item.doctorId !== "all";
      }

      // Otherwise, no items are disabled
      return false;
    },
    selectSpeciality(value) {
      // this.SET_SELECTED_SPECIALITIES(value);
      // const tempDoctors = [...this.doctorsFiltered];
      // this.SET_REPORT_ASSIGNED_DOCTORS(
      //   tempDoctors.filter((doc) => value.includes(doc.speciality))
      // );
      this.chosenSpeciailities = value;
      this.applyFilters();
    },
    selectGovernorate(value) {
      // this.SET_SELECTED_GOVERNATES(value);
      // const tempDoctors = [...this.doctorsFiltered];
      // this.SET_REPORT_ASSIGNED_DOCTORS(
      //   tempDoctors.filter((doc) => value.includes(doc.governorate))
      // );
      this.chosenGovernates = value;
      this.applyFilters();
    },
    applyFilters() {
      // Start with the initial set of doctors after submit
      let tempDoctors = [...this.doctorsFiltered];

      // Apply speciality filter if any specialities are selected
      if (this.chosenSpeciailities.length > 0) {
        tempDoctors = tempDoctors.filter((doc) =>
          this.chosenSpeciailities.includes(doc.speciality)
        );
      }

      // Apply governorate filter if any governorates are selected
      if (this.chosenGovernates.length > 0) {
        tempDoctors = tempDoctors.filter((doc) =>
          this.chosenGovernates.includes(doc.governorate)
        );
      }

      // Update the reportAssignedDoctors with the filtered list
      this.SET_REPORT_ASSIGNED_DOCTORS(tempDoctors);
    },
    onSubmit() {
      this.SET_ASSIGNED_DOCTORS_TABLE(true);
      const doctors = JSON.parse(JSON.stringify(this.assignedDoctors));
      doctors.shift();
      if (this.chosenDoctors.some((doctor) => doctor.doctorId === "all")) {
        this.SET_REPORT_ASSIGNED_DOCTORS(doctors);
        this.doctorsFiltered = doctors;
      } else {
        this.SET_REPORT_ASSIGNED_DOCTORS(this.chosenDoctors);
        this.doctorsFiltered = this.chosenDoctors;
      }

      this.chosenSpeciailities = [];
      this.chosenGovernates = [];
    },
    getSpeciality(id) {
      if (localStorage.getItem("language") == "ar")
        return this.specialities.filter((spec) => spec.value == id)[0].TextAr;
      else
        return this.specialities.filter((spec) => spec.value == id)[0].TextEn;
    },
    getGovernorate(id) {
      if (localStorage.getItem("language") == "ar")
        return this.governorates.filter((gov) => gov.value == id)[0]
          .governorate_name_ar;
      else
        return this.governorates.filter((gov) => gov.value == id)[0]
          .governorate_name_en;
    },
    getPhoneNumber(phoneNumber) {
      return `[${phoneNumber.toString()}]`;
    },
    exportExcel(type, fn, dl) {
      let table = document.getElementById("exportable_table");
      const clone = table.cloneNode(true);
      var row = clone.rows;

      for (var i = 0; i < row[0].cells.length; i++) {
        let str = row[0].cells[i].innerHTML;

        if (
          str.search("Questionnare Status") != -1 ||
          str.search("حالة الاستبيان") != -1
        ) {
          for (var j = 0; j < row.length; j++) {
            // Deleting the ith cell of each row
            row[j].deleteCell(i);
          }
        }
      }

      let wb = utils.book_new();
      let ws = utils.table_to_sheet(clone);

      ws["!cols"] = [];

      // let wb = utils.table_to_book(clone, { sheet: "Sheet JS" });
      ws["!cols"] = [
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
      ]; // hidding col C

      utils.book_append_sheet(wb, ws, "sheet1");

      return dl
        ? write(wb, { bookType: type, bookSST: true, type: "base64" })
        : writeFile(wb, fn || "SheetJSTableExport." + (type || "xlsx"));
    },
  },
  computed: {
    ...mapGetters({
      events: "events",
      assignedDoctors: "assignedDoctors",
      selectedDoctor: "selectedDoctor",
      selectedEvent: "selectedEvent",
      selectedDoctors: "selectedDoctors",
      selectedEvents: "selectedEvents",
      showAssignedDoctorsTable: "showAssignedDoctorsTable",
      reportAssignedDoctors: "reportAssignedDoctors",
      selectedSpeciality: "selectedSpeciality",
      selectedGovernorate: "selectedGovernorate",
    }),
    specialities: () => {
      return constants.specialities;
    },
    governorates() {
      return constants.governorates;
    },
  },
};
</script>

<style lang="scss" scoped>
#event-details-report {
  background: #fff;
  padding: 20px;

  h1 {
    font-size: 20px;
    color: #3c4eba;
    font-weight: 500;
  }

  form {
    border: 1px solid #494b53;
    padding: 15px;
    label {
      font-weight: 400;
      font-size: 16px;
      color: #3c4eba;
    }

    button {
      width: 150px;
    }
  }

  h3 {
    font-size: 16px;
  }
}
::v-deep .v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 40px !important;
}

::v-deep .v-application--wrap {
  min-height: 50vh;
}
</style>
